<template>
  <section>
    <div class="richtext">
      <p>
        Motor driven metering pump has to be strong and&nbsp; stable. Every
        series of NEWDOSE motor-driven dosing pumps offer a range of different
        models, with manual or automatic signal control (4-20mA), for viscous
        liquids, with different dosing systems, different parameters, different
        functions. Every model, in turn, is available with different flow rates
        and working pressures to meet every need. NEWDOSE motor-driven metering
        pumps offer few liters to 6000 l/h, with a working pressure that can
        reach up to 30 bar in the ND (high-pressure) models.&nbsp;
      </p>
      <h2>Benefits of Motor Driven Metering Pumps</h2>
      <p>
        The performance range of motor-driven dosing pumps is very wide. They
        can provide extremely accurate measurements even under fluctuating
        pressure conditions. This can reduce chemical consumption and achieve
        precise process control. These pumps always provide powerful low-cost
        power. They are also flexible: they can be controlled by stroke length
        and motor speed, so that they can be seamlessly integrated and/or
        modified in the automation process. Energy consumption depends on the
        power required, not the other way around. Finally, a specially designed
        metering profile can achieve the best metering results.
      </p>
      <h2>Working Principle of Electric Diaphragm Pump</h2>
      <p>
        The motor drives the helical gear reduction box connected with the main
        shaft to form a reduction mechanical structure, the bearing is on the
        eccentric shaft, the eccentric shaft rotates under the drive of the
        output shaft of the reduction box, the bearing performs eccentric motion
        on the eccentric circle of the eccentric shaft, the generated eccentric
        distance is converted into linear motion of the push rod, and the push
        rod drives the diaphragm to do linear reciprocating motion with the
        cooperation of the restoring force of the spring. A reciprocating cycle
        is called a stroke, the reciprocating distance is called the stroke
        length, and the number of reciprocating cycles per minute is called the
        stroke frequency. When the push rod pulls the diaphragm to move
        backward, the volume between the diaphragm and the pump head increases
        to generate negative pressure, the one-way valve of the suction pipeline
        opens, and the medium in the inlet pipeline enters the cavity of the
        motor-driven dosing pump head. When the suction stroke ends, the
        diaphragm movement stops instantly, the pressure in the pump head is
        balanced with the pressure in the inlet pipe, and the one-way valve of
        the suction port is reset. During the discharge stroke, the diaphragm
        connected with the slide bar moves forward, and the pressure in the pump
        head immediately rises. When the pump head pressure is higher than the
        outlet pressure, the outlet check valve is opened, and the medium in the
        pump head enters the discharge line. When the discharge stroke ends, the
        diaphragm movement stops again, the pressure in the pump head is equal
        to the outlet pressure, and the outlet check valve resets, and then
        enters the next cycle, thus forming the transmission of liquid in the
        form of pulses. Rotate the stroke adjusting rod, which rotates to feed
        or move away from the box body, so that the distance traveled by the
        push rod is correspondingly reduced or increased, thereby adjusting the
        stroke size and finally achieve the purpose of changing the stroke
        adjusting flow rate. Motor X Micro-mechanical Motor Dosing Pump Motor X
        Micro-mechanical Motor Dosing Pump 1-18Lph 2-7bar Learn More
      </p>
    </div>
  </section>
</template>
<script>
export default {
  title: "Motor Driven Diaphragm Pump",
}
</script>